.home {
  margin-top: 24px;
}
.bannerTitle {
  font-size: 34px;
  padding-bottom: 20px;
  text-align: center;
}

.bannerTitle > span:nth-child(2) {
  color: #2596be;
}

.bannerContent {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .bannerContent {
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }
}
