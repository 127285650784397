#speedometerWraper-myValues .envelope {
  width: 336px;
  height: 336px;
}
#speedometerWraper-myValues .speedNobe {
  height: 20px;
  top: 158px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}
#speedometerWraper-myValues .speedPosition {
  width: 56px;
  height: 56px;
  top: 140px;
  left: 140px;
}
#speedometerWraper-myValues .speedNobe div {
  width: 169px;
  left: 42px;
}
#speedometerWraper-myValues .nob {
  width: 54px;
  height: 46px;
}
#speedometerWraper-myValues .numb {
  width: 30px;
  height: 16px;
}
#speedometerWraper-myValues .midNob {
  width: 10px;
  height: 3px;
}

.envelope {
  background: url("../assets/guage.png") no-repeat 0 0 transparent;
  position: relative;
  height: 335px !important;
  background-size: contain;
  margin: 0 60px;
}

.speedNobe {
  position: absolute;
  width: 100%;
  z-index: 20;
  transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  -moz-transition: all 1s;
}

.speedNobe div {
  background: url("../assets/speedNobeNew.png") no-repeat 0 0 transparent;
  position: absolute;
  height: 100%;
  top: 0;
}

.speedometerControl.speedometerControl-Digital .speedNobe div {
  background: url("../assets/speedNobeNew.png") no-repeat 0 0 transparent;
}

#speedometerWraper-myValues .speedNobe {
  height: 20px;
  top: 158px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}

.speedPosition {
  background: url("../assets/speedNobeNew.png") no-repeat 0 0 transparent;
  overflow: hidden;
  text-indent: 500px;
  position: absolute;
  color: #fff;
  text-align: center;
  line-height: 80px;
  font-family: arial;
  font-size: 30px;
  font-weight: bold;
}

.speedometerControl.speedometerControl-Digital .speedPosition {
  background: url("../assets/speedPositionBg.png") no-repeat 0 0 transparent;
}

.clock {
  position: absolute;
  bottom: -18px;
  right: 48px;
}

.speed {
  position: absolute;
  top: 70px;
  left: 55px;
  color: white;
}

.offerButton {
  width: 153px;
  position: absolute;
  bottom: -90px;
  left: 270px;
  cursor: pointer;
}

.offerButtonText {
  position: absolute;
  top: 95%;
  left: 92%;
  text-decoration: none;
  color: #fff;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 24px;
}

@media only screen and (max-width: 900px) {
  .link {
    display: none;
  }
  .envelope {
    margin: 0;
  }
  .bannerTitle {
    padding-bottom: 0;
  }
}
