:root {
  /* Colors */
  --color-orange: #e8952c;
  --color-blue: #2596be;
  --color-lightblue: #A2E5F4;
  --color-gray: rgb(212, 206, 206);
  --color-black: rgba(0, 0, 0, 0.87);
  --color-darknavy:#2E0682;

}
