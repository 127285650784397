body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  /* width: 1065px;
  margin: 0 auto; */
  /* background-color: var(--color-gray); */
}

html {
  scroll-behavior: smooth;
}
