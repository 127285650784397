.slagenLeftWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.slagenLeftWrapper p {
  text-transform: uppercase;
  font-size: 70px;
  color: var(--color-orange);
  font-weight: bolder;
  line-height: 80px;
  letter-spacing: normal;
}

@media only screen and (max-width: 900px) {
  .slagenLeftWrapper p {
    font-size: 40px;
    margin-right: 10px;
  }
  .slagenLeftWrapper {
    flex-direction: row;
  }
}
