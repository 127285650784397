h1,
h2,
h3,
p,
span {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

ul,
li {
  /* margin: 0;
  padding: 0; */
  /* list-style: none; */
}
