.slagenRightWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 55px;
}

.slagenRightWrapper p:nth-child(1) {
  text-transform: uppercase;
  font-size: 60px;
  color: var(--color-orange);
  font-weight: bolder;
}

.slagenRightWrapper p:nth-child(2) {
  text-transform: uppercase;
  font-size: 70px;
  color: var(--color-orange);
  font-weight: bolder;
}

.slagenRightWrapper p:nth-child(3) {
  text-transform: uppercase;
  font-size: 40px;
  color: var(--color-orange);
  font-weight: 600;
}

.slagenRightWrapper hr {
  width: 100%;
  border: 3px solid rgba(128, 128, 128, 0.421);
}
