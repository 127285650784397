.vertical-timeline::before {
  height: 95% !important;
}

@media (max-width: 1170px) {
  .howItWorksButton {
    width: 100%;
    text-align: left;
    padding-left: 60px;
  }

  .vertical-timeline::before {
    height: 70% !important;
    top: 28px !important;
  }
}
